import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import moment from 'moment';
import {
  indexChallengesRequest,
  markChallengesAsReadRequest
} from '../../requests/challenge';
import ChallengesList from './ChallengesList';
import { emptyUnreadChallenges } from '../../actions/utils';
import { Slides, Loader, BasicDropdown } from '../../components';
import { indexSlidesRequest } from '../../requests/slides';
import ChallengeLayout from './ChallengeLayout';

const options = (currentMonth, currentMonth1, currentMonth2, lastMonth) => {
  return [
    {
      key: 1,
      title: currentMonth,
      value: moment(lastMonth).format('YYYY/MM/DD')
    },
    {
      key: 2,
      title: currentMonth1,
      value: moment(lastMonth)
        .subtract(1, 'month')
        .format('YYYY/MM/DD')
    },
    {
      key: 3,
      title: currentMonth2,
      value: moment(lastMonth)
        .subtract(2, 'month')
        .format('YYYY/MM/DD')
    },
    {
      key: 4,
      title: 'Todos',
      value: 'all'
    }
  ];
};

const ChallengeIndex = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [challenges, setChallenges] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [defaultChallenges, setDefaultChallenges] = useState([]);
  const [totalChallenges, setTotalChallenges] = useState([]);
  const [slides, setSlides] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [titleSelect, setTitleSelect] = useState('Cargando...');
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const width = window.innerWidth;

  const lastMonth = user.last_month_active_challenge;
  const currentLastMonth = lastMonth;

  // if (lastMonth) {
  //   localStorage.setItem('lastMonth', lastMonth);
  // }

  const currentMonth = moment(currentLastMonth).format('MMMM');
  const currentMonth1 = moment(currentLastMonth)
    .subtract(1, 'month')
    .format('MMMM');
  const currentMonth2 = moment(currentLastMonth)
    .subtract(2, 'month')
    .format('MMMM');

  const sortColumns = options(
    currentMonth,
    currentMonth1,
    currentMonth2,
    currentLastMonth
  );

  const [sortBy, setSortBy] = useState(sortColumns[0]);

  const settingsSlider = {
    infinite: true,
    speed: 500,
    slidesToShow: highlights?.length < 3 ? highlights?.length : 3,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 1
        }
      }
    ]
  };

  const {
    settings: { menuType, challengeWord }
  } = useSelector(state => state.utils);

  const handleindexChallengesRequest = () => {
    setOnRequest(true);

    const params = {
      challenge_type: 0,
      filter_by_month: firstRender ? sortColumns[0].value : sortBy.value
    };

    setTitleSelect(moment(params.filter_by_month).format('MMMM'));

    if (sortBy.value === 'all') {
      delete params.filter_by_month;
      setTitleSelect('Todos');
    }

    indexChallengesRequest({
      dispatch,
      params,
      successCallback: result => {
        setChallenges(
          camelCaseRecursive(
            result.data.data.filter(i => !i.highlight && i.format !== 'default')
          )
        );
        setHighlights(
          camelCaseRecursive(
            result.data.data.filter(i => i.highlight && i.format !== 'default')
          )
        );
        setDefaultChallenges(
          camelCaseRecursive(
            result.data.data.filter(i => i.format === 'default')
          )
        );
        setTotalChallenges(result.data.data);
        setOnRequest(false);
        setFirstRender(false);
      }
    });
  };

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 1
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(() => {
    if (currentLastMonth) {
      handleindexChallengesRequest();
    }
  }, [sortBy, currentLastMonth]);

  useEffect(() => {
    handleIndexSlidesRequest();

    markChallengesAsReadRequest({
      dispatch
    });

    dispatch(emptyUnreadChallenges());
  }, [dispatch]);

  const slider = () => {
    return (
      <Slider {...settingsSlider}>
        {highlights.map(i => (
          <ChallengeLayout
            key={`id-${i.id.toString()}`}
            type="highlight"
            challenge={i}
          />
        ))}
      </Slider>
    );
  };

  return (
    <div className="challenge-index">
      <Slides items={slides} />
      <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3">
        <h2 id="nn-slides-title" className="screen-title mb-0">
          {challengeWord || 'Desafíos'}
        </h2>
        <div>
          {lastMonth && currentLastMonth && (
            <BasicDropdown
              variant="outline-gray"
              active={sortBy.key}
              onChange={setSortBy}
              items={sortColumns}
              titleDrop={titleSelect.toLocaleUpperCase()}
              containerStyle={{
                textAlign: 'end',
                width: 150
              }}
              buttonStyle={{
                width: '100%',
                borderRadius: 15
              }}
            />
          )}
        </div>
      </div>
      {onRequest && (
        <div
          className="container-loader"
          style={menuType === 1 ? { width: '100%' } : {}}
        >
          <Loader />
        </div>
      )}
      <div className={menuType === 1 ? 'margin-card-products' : ''}>
        <div className="gap-40 mb-5 container-highlight">
          {width <= 991 && highlights.length > 1 ? (
            slider()
          ) : (
            <>
              {highlights.length === 1 && (
                <div className="d-flex justify-content-center">
                  <ChallengeLayout
                    key={`id-${highlights[0]?.id.toString()}`}
                    type="highlight"
                    challenge={highlights[0]}
                  />
                </div>
              )}
              {highlights.length === 2 && width > 991 && (
                <div className="d-flex justify-content-center gap-50">
                  {highlights.map(i => (
                    <ChallengeLayout
                      key={`id-${i.id.toString()}`}
                      type="highlight"
                      challenge={i}
                    />
                  ))}
                </div>
              )}
              {highlights.length > 2 && slider()}
            </>
          )}
        </div>
        <Row>
          {challenges.map(i => (
            <Col xl={6} key={`id-${i.id.toString()}`}>
              <ChallengeLayout type={i.format} challenge={i} />
            </Col>
          ))}
        </Row>
        {defaultChallenges.length > 0 && (
          <ChallengesList challenges={defaultChallenges} />
        )}
        {totalChallenges.length === 0 && !onRequest && (
          <p className="text-center text-empty mt-5">Sin resultados</p>
        )}
      </div>
    </div>
  );
};

export default withRouter(connect()(ChallengeIndex));
