import React, { useEffect } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { ProSidebar } from 'react-pro-sidebar';
import { validateToken } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';
import { NotificationsDropdown, ProfileDropdown } from '../Dropdown';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import { getUnreadChallenges, getUnreadNews } from '../../../actions/utils';
import MenuLinks from '../../../config/MenuLinks';
import baseColors from '../../../assets/stylesheets/base_colors';

const NavbarLogged = props => {
  const { user } = useSelector(state => state.auth);
  const {
    settings: { nameWord, showCompleteWord }
  } = useSelector(state => state.utils);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const updateAbilityRoles = () => {
    updateAbility(ability, user.role);
  };

  useEffect(() => {
    if (user.id === 0) {
      dispatch(validateToken());
    } else {
      dispatch(getUnreadChallenges());
      dispatch(getUnreadNews());
    }
  }, [dispatch, user.id]);

  useEffect(updateAbilityRoles, [user]);

  const { handleToggleSidebar, menu, unreadChallenges, unreadNews } = props;
  return (
    <PrincipalNavbar onClick={handleToggleSidebar}>
      {menu && (
        <ProSidebar className="navbar-menu mr-3" style={{ width: 'auto' }}>
          <MenuLinks
            navbar
            unreadChallenges={unreadChallenges}
            unreadNews={unreadNews}
            handleToggleSidebar={handleToggleSidebar}
          />
        </ProSidebar>
      )}
      <div className="d-flex ml-auto flex-row mb-lg-2">
        <Link
          className={`d-flex align-items-center text-decoration-none mr-2 ml-3 ${baseColors.badgePoints}`}
          to="/points-summary"
        >
          {showCompleteWord === 1 ? (
            <Badge className="badge-gray hover-badge">
              {nameWord}: {user.parsed_balance}
            </Badge>
          ) : (
            <Badge className="badge-gray hover-badge">
              {user.parsed_balance} {nameWord?.split('')[0]}.
            </Badge>
          )}
        </Link>
        <NotificationsDropdown />
        <ProfileDropdown loggedIn />
      </div>
    </PrincipalNavbar>
  );
};

export default NavbarLogged;
