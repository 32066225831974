import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BasicCard, Loader, Slides } from '../../components';
import { indexSlidesRequest } from '../../requests/slides';
import showMeRequest from '../../requests/auth';
import RedeemsDataTable from '../Redeem/RedeemsDataTable';
import rintipetLogo from '../../assets/images/rintipet.png';
// import { indexProductsRequest } from '../../requests/products';
// import Product from '../Product/ProductCard';
import './styles.scss';
import baseColors from '../../assets/stylesheets/base_colors';

const Profile = () => {
  // const [products, setProducts] = useState([]);
  const [user, setUser] = useState({ avatar: [] });
  const [onRequest, setOnRequest] = useState(true);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const {
    settings: {
      nameKpi1,
      nameKpi2,
      nameKpi3,
      nameKpi4,
      nameWord,
      kpi4Available,
      hideChallengeWindow,
      hideProductsLink,
      hideProgressPoints,
      reorderProfile
    }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 9
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setOnRequest(false);
      }
    });
  };

  useEffect(() => {
    setOnRequest(true);
    const dispatchGetMe = () => {
      showMeRequest({
        dispatch,
        params: {
          date_from: '',
          date_to: ''
        },
        successCallback: response => {
          if (response.data.logged_in) {
            setUser(camelCaseRecursive(response.data.user_info));
          }
          setOnRequest(false);
        }
      });
    };
    // indexProductsRequest({
    //   dispatch,
    //   successCallback: result => {
    //     setProducts(camelCaseRecursive(result.data.data));
    //   }
    // });
    handleIndexSlidesRequest();
    dispatchGetMe();
  }, [dispatch]);

  const avatarUrl = user.avatar.length ? user.avatar[0].url : '';
  const { monthlyPoints } = user;

  // const kpi1 = monthlyPoints ? monthlyPoints.kpi1Percent * 100 : 0;
  // const kpi2 = monthlyPoints ? monthlyPoints.kpi2Percent * 100 : 0;
  // const kpi3 = monthlyPoints ? monthlyPoints.kpi3Percent * 100 : 0;
  // const kpi4 = monthlyPoints ? monthlyPoints.kpi3Percent * 100 : 0;

  const reorderColProfile = reorderProfile === 1 ? 6 : 5;

  const renderBoxProgress = () => {
    if (reorderProfile === 1) return null;
    return (
      <Row className="my-4">
        <Col md={12}>
          <p className="mt-2 text-h4">{nameWord} totales acumulados</p>
        </Col>
        <Col xs={6}>
          <Card border="primary" style={{ width: '100%', height: 140 }}>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <p className="text-center">{nameKpi1}</p>
                </Col>
                <Col md={12}>
                  <p className="text-center text-h4 mb-1">
                    {user.kpis?.kpi1 || 0}
                  </p>
                  <p className="text-center text-secondary">Puntos</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6}>
          <Card border="primary" style={{ width: '100%', height: 140 }}>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <p className="text-center">{nameKpi2}</p>
                </Col>
                <Col md={12}>
                  <p className="text-center text-h4 mb-1">
                    {user.kpis?.kpi2 || 0}
                  </p>
                  <p className="text-center text-secondary">Puntos</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6}>
          <Card
            border="primary"
            className="mt-3"
            style={{ width: '100%', height: 140 }}
          >
            <Card.Body>
              <Row>
                <Col md={12}>
                  <p className="text-center">{nameKpi3}</p>
                </Col>
                <Col md={12}>
                  <p className="text-center text-h4 mb-1">
                    {user.kpis?.kpi3 || 0}
                  </p>
                  <p className="text-center text-secondary">Puntos</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {kpi4Available > 0 && (
          <Col xs={6}>
            <Card
              border="primary"
              className="mt-3"
              style={{ width: '100%', height: 140 }}
            >
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">{nameKpi4}</p>
                  </Col>
                  <Col md={12}>
                    <p className="text-center text-h4 mb-1">
                      {user.kpis?.kpi4 || 0}
                    </p>
                    <p className="text-center text-secondary">Puntos</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hideChallengeWindow === 1 ? null : (
          <Col xs={kpi4Available > 0 ? 12 : 6}>
            <Card
              border="primary"
              className="mt-3"
              style={{ width: '100%', height: 140 }}
            >
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">Desafíos</p>
                  </Col>
                  <Col md={12}>
                    <p className="text-center text-h4 mb-1">
                      {monthlyPoints && monthlyPoints.challenges} {firstLetter}
                    </p>
                    <p className="text-center text-secondary">Puntos</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <Slides items={slides} />
      <Row id="nn-profile-wrapper" className="mt-4">
        <Col md={12}>
          {onRequest ? (
            <Loader height="120px" minHeight="auto" />
          ) : (
            <Row style={{ alignItems: 'center' }}>
              <Col xs={12} md={4} lg={2} className="profile-img">
                <Image
                  src={avatarUrl}
                  className="avatar ml-xl-5"
                  roundedCircle
                />
              </Col>
              <Col
                className="pt-3 d-flex flex-row flex-wrap"
                xs={12}
                sm={8}
                md={8}
                lg={10}
              >
                <div>
                  <h4 style={{ fontWeight: 700 }}>¡Hola {user.fullName}!</h4>
                  <p>{user.zoneName}</p>
                  <h6 style={{ fontWeight: 700 }}>
                    <Link to="/profile/edit" variant="primary">
                      <AiFillEdit /> Editar Perfil
                    </Link>
                  </h6>
                </div>
                {reorderProfile === 1 ? (
                  <div className="ml-auto mr-4">
                    <img
                      alt="logo rintipet 50 años"
                      className="logo-rintipet"
                      src={rintipetLogo}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          )}
        </Col>

        <Col md={12} lg={hideProductsLink === 1 ? 12 : reorderColProfile}>
          <Row className="mt-5">
            {hideProductsLink === 1 ? null : (
              <>
                <Col xs={6} className="mb-1">
                  <BasicCard
                    className={`card-secondary-bg basic-card min-h ${baseColors.bgColorProfile}`}
                    title={`${nameWord || 'Puntos'} acumuladas`}
                    text={user.balance && user.balance.toFixed(1)}
                  />
                </Col>
                <Col xs={6}>
                  <BasicCard
                    className={`card-secondary-bg basic-card min-h ${baseColors.bgColorProfile}`}
                    title={`${nameWord || 'Puntos'} Canjeados`}
                    text={user.redeemedPoints && user.redeemedPoints.toFixed(1)}
                  />
                </Col>
                {reorderProfile === 1 ? (
                  <div className="w-100 mt-5 mx-2">
                    <p className="text-center text-md font-weight-bold">
                      ¡Alcanza tu cuota en soles y gana premios increíbles!{' '}
                      <br />
                      <span className="text-secondary">
                        Participan todos los productos del portafolio de Rinti
                        S.A.
                      </span>
                      <br />
                      <p className="text-secondary">
                        El cálculo de las {nameWord} que ganarás se hace en base
                        a la cuota sin IGV
                      </p>
                    </p>
                  </div>
                ) : null}
              </>
            )}
          </Row>
          <Row className="mt-3" md={12}>
            {hideProductsLink === 1 ? (
              <Col xs={6}>
                <BasicCard
                  className="card-secondary-bg basic-card"
                  title={`${nameWord} disponibles para canje`}
                  text={user.balance && user.balance.toFixed(1)}
                />
              </Col>
            ) : null}
            <Col md={hideProductsLink === 1 ? 6 : 12}>
              {reorderProfile === 1 ? (
                <>{renderBoxProgress()}</>
              ) : (
                <Card className="card-primary-bg">
                  <Card.Body>
                    <Row style={{ alignItems: 'center' }}>
                      <Col md={6}>
                        <p className="badge-primary text-md">
                          Desafíos realizados del mes
                        </p>
                      </Col>
                      <Col md={6}>
                        <p
                          className="mt-3 badge-primary text-center text-md"
                          style={{ fontSize: 28 }}
                        >
                          {user.challengesResume}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
          {hideProgressPoints === 1 || reorderProfile === 1
            ? null
            : renderBoxProgress()}
        </Col>
        {/* <Col md={7}>
        <h3>Premios favoritos</h3>
        <div className="container testimonial-group">
          <div className="row text-center">
            {products.map(product => (
              <div className="col-5">
                <Product item={product} />
              </div>
            ))}
          </div>
        </div>
      </Col> */}
        {hideProductsLink === 1 ? null : (
          <Col
            md={12}
            lg={reorderProfile === 1 ? 6 : 7}
            className={reorderProfile === 1 ? 'my-4 my-md-0 mt-md-5' : 'my-5'}
          >
            {reorderProfile === 1 ? (
              <>
                <div className="scroll-x bg-datatable">
                  <table className="table-meta-profile">
                    <thead>
                      <tr>
                        <th>
                          <div className="bg-secondary">Periodo</div>
                        </th>
                        <th>
                          <div className="bg-secondary">
                            Cuota <br /> sin IGV
                          </div>
                        </th>
                        <th>
                          <div className="bg-secondary">
                            Cuota <br /> con IGV
                          </div>
                        </th>
                        <th>
                          <div className="bg-secondary">Avance</div>
                        </th>
                        <th>
                          <div className="bg-secondary w-100">
                            {nameWord || 'Premios'} <br /> acumuladas
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {user?.metaValues?.map(i => (
                      <tbody>
                        <tr>
                          <td>{i.metaMonth}</td>
                          <td>
                            <div className="bg-gray-light">{i.metaValue}</div>
                          </td>
                          <td>
                            <div className="bg-gray-light">{i.metaQuote}</div>
                          </td>
                          <td>
                            <div className="bg-gray-light">
                              <div className="left">{i.metaAdvance}</div>
                              <div>{i.metaAdvancePercentage}</div>
                            </div>
                          </td>
                          <td>
                            <div className="bg-gray-light w-100">
                              {i.metaPrize}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
                <h3 className="text-center mt-4 text-meta-prize">
                  {nameWord || 'Premios'} que puedes acumular en total:
                  <span className="bg-primary-color">
                    {user.totalMetaPrize}
                  </span>
                </h3>
              </>
            ) : (
              <RedeemsDataTable
                trakingLink={false}
                searching={false}
                title="Premios canjeados"
              />
            )}
          </Col>
        )}
      </Row>
      {reorderProfile === 1 ? (
        <Row>
          <Col md={12}>
            <div className="mb-md-5 mb-2">
              <RedeemsDataTable
                trakingLink={false}
                searching={false}
                title="Premios canjeados"
              />
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default connect()(Profile);
